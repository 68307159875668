import { graphql } from "gatsby";
import React from "react";
import EventPageTemplate from "./event";

export const query = graphql`
  query EventLandingPageTemplateQuery($id: String!) {
    docs: sanityEvent(id: { eq: $id }) {
      ...EventPage
    }
  }
`;

export const EventLandingPageTemplate = (props) => (
  <EventPageTemplate {...props} hideNav={true} />
);

export default EventLandingPageTemplate;
